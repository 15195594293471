import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faVimeo, faSlack, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ScrollToTop from "./pre_login/parts/ScrollToTop";
import { TRACKING_ID } from '../utils/Settings';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

// const AppStart = lazy(() => import('./pre_login/AppStart'));
const CreateAccount = lazy(() => import('./pre_login/CreateAccount'));
// const DocumentRequest = lazy(() => import('./pre_login/DocumentRequest'));
const AccountSecuritySetting = lazy(() => import('./after_login/account/AccountSecuritySetting'));
// const LoginView = lazy(() => import('./pre_login/LoginView'));
const AccountOtherSetting = lazy(() => import('./after_login/account/AccountOtherSetting'));
const AccountTeamSettings = lazy(() => import('./after_login/account/AccountTeamSettings'));
const PrivacyPolicy = lazy(() => import('./pre_login/PrivacyPolicy'));
const PrivacyPolicyEn = lazy(() => import('./pre_login/PrivacyPolicyEn'));
const EmailValidate = lazy(() => import('./pre_login/EmailValidate'));
const WaitVerification = lazy(() => import('./pre_login/WaitVerification'));
const VimeoRedirect = lazy(() => import('./pre_login/VimeoRedirect'));
const YoutubeRedirect = lazy(() => import('./pre_login/YoutubeRedirect'));
const SlackRedirect = lazy(() => import('./pre_login/SlackRedirect'));
// const ForgetPasswordView = lazy(() => import('./pre_login/ForgetPasswordView'));
const ResetPasswordView = lazy(() => import('./pre_login/ResetPasswordView'));
const AzureRedirect = lazy(() => import('./pre_login/AzureRedirect'));
const NaranciaTop = lazy(() => import('./after_login/narancia/NaranciaTop'));
const NaranciaUpload = lazy(() => import('./after_login/narancia/NaranciaUpload'));
const NaranciaAnnotation = lazy(() => import('./after_login/narancia/NaranciaAnnotationSplit'));
const NaranciaConcat = lazy(() => import('./after_login/narancia/NaranciaConcat'));
const NaranciaCreateCanvas = lazy(() => import('./after_login/narancia/NaranciaCreateCanvas'));
const AdminTop = lazy(() => import('./after_login/admin/AdminTop'));
const SubscribePreview = lazy(() => import('./after_login/miscellaneous/SubscribePreview'));
const StatsTop = lazy(() => import('./after_login/stats/StatsTop'));
// const ContactView = lazy(() => import('./after_login/miscellaneous/ContactView'));
const StripeSuccess = lazy(() => import('./after_login/miscellaneous/StripeSuccess'));
const StripeCancel = lazy(() => import('./after_login/miscellaneous/StripeCancel'));
const TutorialView = lazy(() => import('./after_login/miscellaneous/TutorialView'));
const LandingPage = lazy(() => import('./pre_login/LandingPage'));
// const UseCases = lazy(() => import('./pre_login/UseCases'));
// const FeatureList = lazy(() => import('./pre_login/FeatureList'));
const GoogleCalendarRedirect = lazy(() => import('./pre_login/GoogleCalendarRedirect'));
const PricingView = lazy(() => import('./pre_login/PricingView'));
const AnswerTop = lazy(() => import('./after_login/answer/AnswerTop2'));
const ThirdConnectTop = lazy(() => import('./after_login/third_party_connect/ThirdConnectTop'));
const AnswerFileUpload = lazy(() => import('./after_login/answer/AnswerFileUpload'));
const VectorsTop = lazy(() => import('./after_login/vectors/VectorsTop'));
const VectorItemAdd = lazy(() => import('./after_login/vectors/VectorItemAdd'));
const ResourcesTop = lazy(() => import('./after_login/resources/ResourcesTop'));
const ResourceFileUploadOne = lazy(() => import('./after_login/resources/ResourceFileUploadOne'));
const EvidenceDataTop = lazy(() => import('./after_login/vectors/EvidenceDataTop'));
const EvidenceItemEdit = lazy(() => import('./after_login/vectors/EvidenceItemEdit'));
const PartnerCreateAccount = lazy(() => import('./pre_login/PartnerCreateAccount'));
const AgentCreateTraial = lazy(() => import('./pre_login/AgentCreateTraial'));
const AgentCreateAccount = lazy(() => import('./pre_login/AgentCreateAccount'));
const NaranciaVideoPlayer = lazy(() => import('./after_login/narancia/NaranciaVideoPlayer'));
const JssaCreateAccount = lazy(() => import('./pre_login/JssaCreateAccount'));
const Maintenance = lazy(() => import('./pre_login/Maintenance'));
// const NotFound = lazy(() => import('./pre_login/NotFound'));
const ErrorPage = lazy(() => import('./pre_login/ErrorPage'));
const ZoomSettings = lazy(() => import('./after_login/account/ZoomSettings'));
const LoginWithCodeView = lazy(() => import('./pre_login/LoginWithCodeView'));
const PromptManagerTop = lazy(() => import('./after_login/prompt/PromptManagerTop'));
const TranslateManagerTop = lazy(() => import('./after_login/translate/TranslateManagerTop')); 
const DocumentEn = lazy(() => import('./pre_login/DocumentEn')); 
const AccountCalendarSettingsV2 = lazy(() => import('./after_login/account/AccountCalendarSettingsV2'));
const ZeroTrustTop = lazy(() => import('./after_login/zerotrust/ZeroTrustTop'));
const AdminPrivateTop = lazy(() => import('./after_login/admin/AdminPrivateTop'));

const AppStart_v2 = lazy(() => import('./pre_login/AppStart_v2'));
const ContactView_v2 = lazy(() => import('./after_login/miscellaneous/ContactView_v2'));
const LoginView_v2 = lazy(() => import('./pre_login/LoginView_v2'));
const ForgetPasswordView_v2 = lazy(() => import('./pre_login/ForgetPasswordView_v2'));
const DocumentRequest_v2 = lazy(() => import('./pre_login/DocumentRequest_v2'));
const FeatureList_v2 = lazy(() => import('./pre_login/FeatureList_v2'));
const UseCases_v2 = lazy(() => import('./pre_login/UseCases_v2'));
const NotFound_v2 = lazy(() => import('./pre_login/NotFound_v2'));
const Faq_v2 = lazy(() => import('./pre_login/Faq_v2'));
const AboutSecurity_v2 = lazy(() => import('./pre_login/AboutSecurity_v2'));
const PartnerDashboardTop = lazy(() => import('./after_login/admin/PartnerDashboardTop'));

const InformationMessagesTop = lazy(() => import('./after_login/narancia/InformationMessagesTop'));

library.add(fas, faVimeo, faSlack, faYoutube)

const Loading = () => {
  return (
    <p style={{
      color: '#555555',
    }}>
      Loading...
    </p>
  )
}

// App
const App = (props) => {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send("pageview");
  }, []);

  
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <div className="App" style={{
      fontFamily: 'BIZ UDPGothic',
    }}>

      <Suspense fallback={<Loading />}>

        <ScrollToTop />

        <Routes>

          <Route path="/" element={<AppStart_v2 />}></Route>
          <Route path="/index.html" element={<AppStart_v2 />}></Route>

          <Route path="/contactus.html?msg_type=5" element={<CreateAccount />}></Route>
          <Route path="/login.html" element={<LoginView_v2 />}></Route>
          <Route path="/forget_password.html" element={<ForgetPasswordView_v2 />}></Route>
          <Route path="/reset_password.html" element={<ResetPasswordView />}></Route>
          <Route path="/document_request.html" element={<DocumentRequest_v2 />}></Route>

          <Route path="/partner.html" element={<PartnerCreateAccount />}></Route>
          <Route path="/agent_create_account.html" element={<AgentCreateAccount />}></Route>
          <Route path="/agent_create_trial.html" element={<AgentCreateTraial />}></Route>
          
          
          <Route path="/lp.html" element={<LandingPage />}></Route>
          <Route path="/usecases.html" element={<UseCases_v2 />}></Route>
          <Route path="/feature_list.html" element={<FeatureList_v2 />}></Route>
          <Route path="/faq.html" element={<Faq_v2 />}></Route>
          <Route path="/about_security.html" element={<AboutSecurity_v2 />}></Route>
          

          {/* <Route path="/account.html" element={<AccountBase />}></Route> */}
          <Route path="/account_security_setting.html" element={<AccountSecuritySetting />}></Route>
          <Route path="/account_other_setting.html" element={<AccountOtherSetting />}></Route>
          <Route path="/calendar_settings.html" element={<AccountCalendarSettingsV2 />}></Route>

          <Route path="/privacy_policy.html" element={<PrivacyPolicy />}></Route>
          <Route path="/privacy_policy_en.html" element={<PrivacyPolicyEn />}></Route>
          <Route path="/email_validate.html" element={<EmailValidate />}></Route>
          <Route path="/pre_registration.html" element={<WaitVerification />}></Route>
          <Route path="/vimeo_redirect.html" element={<VimeoRedirect />}></Route>
          <Route path="/youtube_redirect.html" element={<YoutubeRedirect />}></Route>
          <Route path="/slack_redirect.html" element={<SlackRedirect />}></Route>
          <Route path="/recall_azure_redirect.html" element={<AzureRedirect />}></Route>

          <Route path="/google_calendar_redirect.html" element={<GoogleCalendarRedirect />}></Route>

          <Route path="/narancia_top.html" element={<NaranciaTop />}></Route>
          <Route path="/narancia_upload.html" element={<NaranciaUpload />}></Route>
          <Route path="/narancia_upload_azure_redirect.html" element={<NaranciaUpload />}></Route>
          <Route path="/narancia_annotation.html" element={<NaranciaAnnotation />}></Route>
          <Route path="/narancia_concat.html" element={<NaranciaConcat />}></Route>
          <Route path="/narancia_create_canvas.html" element={<NaranciaCreateCanvas />}></Route>

          <Route path="/admin_top.html" element={<AdminTop />}></Route>

          <Route path="/stats_top.html" element={<StatsTop />}></Route>

          <Route path="/subscribe_preview.html" element={<SubscribePreview />}></Route>
          <Route path="/contactus.html" element={<ContactView_v2 />}></Route>
          <Route path="/stripe_success.html" element={<StripeSuccess />}></Route>
          <Route path="/stripe_cancel.html" element={<StripeCancel />}></Route>
          <Route path="/tutorial.html" element={<TutorialView />}></Route>
          <Route path="/pricing.html" element={<PricingView />}></Route>

          <Route path="/answer_top.html" element={<AnswerTop />}></Route>
          <Route path="/answer_file_upload.html" element={<AnswerFileUpload />}></Route>
          <Route path="/third_connect_top.html" element={<ThirdConnectTop />}></Route>

          <Route path="/vectors_top.html" element={<VectorsTop />}></Route>
          <Route path="/vector_item_add.html" element={<VectorItemAdd />}></Route>
          <Route path="/resources_top.html" element={<ResourcesTop />}></Route>
          <Route path="/resource_item_add.html" element={<ResourceFileUploadOne />}></Route>
          <Route path="/evidense_top.html" element={<EvidenceDataTop />}></Route>
          <Route path="/evidense_edit.html" element={<EvidenceItemEdit />}></Route>
          
          <Route path="/narancia_video_player.html" element={<NaranciaVideoPlayer />}></Route>

          <Route path="/informations_top.html" element={<InformationMessagesTop />}></Route>
          

          <Route path="/maintenance.html" element={<Maintenance />}></Route>
          <Route path="/not_found.html" element={<NotFound_v2 />}></Route>
          <Route path="/error_page.html" element={<ErrorPage />}></Route>
          
          <Route path="/jssa.html" element={<JssaCreateAccount />}></Route>

          <Route path="/account_team_settings.html" element={<AccountTeamSettings />}></Route>
          <Route path="/zoom_settings.html" element={<ZoomSettings />}></Route>

          <Route path="/login_with_code.html" element={<LoginWithCodeView />}></Route>
          <Route path="/prompt_manager_top.html" element={<PromptManagerTop />}></Route>
          <Route path="/translate_manager_top.html" element={<TranslateManagerTop />}></Route>
          
          <Route path="/document_en.html" element={<DocumentEn />}></Route>

          <Route path="/zerotrust_top.html" element={<ZeroTrustTop />}></Route>

          <Route path="/admin_private.html" element={<AdminPrivateTop />}></Route>
          <Route path="/partner_dashboard.html" element={<PartnerDashboardTop />}></Route>


          <Route path="*" element={<NotFound_v2 />} />
          
        </Routes>

      </Suspense>

    </div>
  )
}

export default App
