import {
  LOGIN_EMAIL,
  SET_STREAM,
  TOGGLE_FILLER_HIDE,
  SET_AD_CODE,
  SET_GUEST_MODE,
} from '../actions/Types'

function user (state = {}, action) {

  const { params } = action

  switch (action.type) {

    case LOGIN_EMAIL :

      return {
        ...state,
        user_info: params.user_info,
        login_token: params.login_token,
        createdAt: params.createdAt,
      }

    case TOGGLE_FILLER_HIDE :

      console.log("TOGGLE_FILLER_HIDE: ", params)

      return {
        ...state,
        show_filler: params.show_filler,
      }

    case SET_STREAM :
      return {
        ...state,
        streams: params.streams,
      }

    case SET_AD_CODE :
      return {
        ...state,
        ad_code: params.ad_code,
      }

    case SET_GUEST_MODE:

      // console.log("SET_GUEST_MODE", params.guest_mode)
    
      return {
        ...state,
        guest_mode: params.guest_mode,
      }

    default :
      return state
  }
}

export default user
