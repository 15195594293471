//
// rest api
//

let _api_url = 'https://editor.shabelab.com'
if ( window.location.hostname === "localhost" ) {
  _api_url = 'https://editordev.shabelab.com'
  // _api_url = 'https://editortest.shabelab.com'
} else if ( window.location.hostname.indexOf("editortest.shabelab.com") !== -1 ) {
  _api_url = 'https://editortest.shabelab.com'
} else if ( window.location.hostname.indexOf("shabelab.com") !== -1 ) {
  _api_url = 'https://'+window.location.hostname
}


export const API_URL = _api_url
export const API_KEY = 'ze943igzyu0ortvy'
export const LOCAL_STRAGE_KEY = "__EDITOR_SHABELAB__"
export const BG_COLOR = "#111111"
export const G_CLIENT_ID = "6648391901-0uvj2q65l55qafn16kiu927h8hdmn4a4.apps.googleusercontent.com"

export const ENCRYPTION_KEY = "f3e9b15dcd4c6f330ecf60fbc1125ab1aa7e40f80d5788675dc087b823a2ce91"
export const ENCRYPTION_IV = "f3dbcfcc0d48d55fceebdd1b70c8e60e"

export const SOCKETIO_URL = _api_url

export const TRACKING_ID = "G-XBPJBLX4TX";