import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom'
import { createStore } from 'redux'
import reducer from './reducers'
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProSidebarProvider } from 'react-pro-sidebar';
import './components/pre_login/AppStart_v2_parts/style.css';


// prepare store
const store = createStore(
  reducer,
)

// <BrowserRouter basename={'/annotation_tool'}>
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ProSidebarProvider>
        <SnackbarProvider maxSnack={3}>

            <App />

        </SnackbarProvider>
      </ProSidebarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
